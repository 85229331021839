<template>
  <ul :class="listClass">
    <li
      v-for="(option, index) in options"
      ref="listItems"
      :key="getOptionValue(option)"
      :class="listItemClass"
    >
      <Button
        v-bind="buttonProps"
        :active="isActive(option)"
        :disabled="isLocked(option)"
        :can-show-chip-active-icon="getCanShowChipActiveIcon(option)"
        @click="handleClick(option, index)"
      >
        <span
          v-if="getCanShowIcon(option)"
          v-bind="getIconProps(option)"
        />
        {{ getOptionLabel(option) }}
      </Button>
    </li>
  </ul>
</template>

<script>
import { ButtonTypes } from '@@/components/Common//Button.vue';
import { getMaskImageStyle } from '@@/utils/CommonUtils';
import { scrollActiveItemIntoView } from '@@/utils/Components/ComponentUtils';

export default {
  name: 'Chips',

  props: {
    canShowChipActiveIcon: {
      type: Boolean,
      default: true,
    },

    canShowLockedIcon: {
      type: Boolean,
      default: true,
    },

    isAllAccess: {
      type: Boolean,
      default: undefined,
    },

    /**
     * When false only one chip may be selected.
     */
    multiple: {
      type: Boolean,
      default: true,
    },

    noWrap: {
      type: Boolean,
      default: false,
    },

    /**
     * Array of strings or array of { label, value } objects.
     */
    options: {
      type: Array,
      required: true,
    },

    shouldScrollActiveItemIntoView: {
      type: Boolean,
      default: false,
    },

    secondary: {
      type: Boolean,
      default: false,
    },

    /**
     * When this option value is selected all other options will be deselected. When a different
     * option is selected this value will be deselected.
     */
    selectAllOptionValue: {
      type: String,
      default: null,
    },

    /**
     * Array of strings of option values that will be displayed as active chips.
     */
    modelValue: {
      type: Array,
      default() { return []; },
    },
  },

  emits: ['update:modelValue'],

  computed: {
    buttonProps() {
      if (this.secondary) {
        return {
          small: true,
          type: ButtonTypes.chipSecondary,
        };
      }

      return { type: ButtonTypes.chip };
    },

    listClass() {
      return this.noWrap
        ? `tw-whitespace-nowrap tw-overflow-x-scroll hide-scrollbar-on-mobile ${this.$style.list}`
        : null;
    },

    listItemClass() {
      return `tw-inline-block tw-mr-1.5 ${this.noWrap ? '' : 'tw-mb-1.5'}`;
    },
  },

  mounted() {
    if (this.shouldScrollActiveItemIntoView) {
      const firstActiveOption = this.options.findIndex((option) => this.isActive(option));
      scrollActiveItemIntoView(this, 'listItems', firstActiveOption);
    }
  },

  methods: {
    getCanShowChipActiveIcon(option) {
      if (option.icon) {
        return false;
      }

      return this.canShowChipActiveIcon;
    },

    getCanShowIcon(option) {
      if (option.icon) {
        return true;
      }

      return this.isLocked(option) && this.canShowLockedIcon;
    },

    getIconProps(option) {
      const iconProps = {
        class: 'tw-inline-block tw-w-4 tw-h-4 tw-align-middle',
      };

      if (this.isLocked(option)) {
        return {
          ...iconProps,
          style: {
            backgroundColor: 'var(--saturated-teal)',
            ...getMaskImageStyle('https://blizzard.opensnow.com/icons/custom/green-lock.svg'),
          },
        };
      }

      return {
        ...iconProps,
        style: {
          backgroundColor: 'currentColor',
          ...getMaskImageStyle(option.icon),
        },
      };
    },

    getOptionLabel(option) {
      return typeof option === 'object' ? option?.label : option;
    },

    getOptionValue(option) {
      return typeof option === 'object' ? option?.value : option;
    },

    /**
     * @todo Blur button on click (likely needed as a prop or default behavior in <Button>).
     */
    handleClick(option, optionIndex) {
      const newValue = [...this.modelValue];
      const optionValue = this.getOptionValue(option);

      // Special cases for single option selectable (multiple is false):
      //
      // - When the option is already selected do nothing
      // - When a new option is selected then remove the old value

      if (this.multiple === false) {
        if (newValue.includes(optionValue)) {
          return;
        }

        newValue.splice(0, Infinity);
      }

      // Special cases for the selectAllOptionValue:
      //
      // - When the select all option value is selected then deselect all other options.
      // - When the select all option is selected and another option is selected then unselect the
      //   select all option.

      if (this.selectAllOptionValue) {
        if (optionValue === this.selectAllOptionValue) {
          newValue.splice(0, Infinity);
        }
        else if (newValue.includes(this.selectAllOptionValue)) {
          const index = newValue.findIndex((e) => e === this.selectAllOptionValue);
          newValue.splice(index, 1);
        }
      }

      // General cases:
      //
      // - Remove the option value from the new value if the option is unselected
      // - Add the option value to the new value if not already selected

      if (newValue.includes(optionValue)) {
        const index = newValue.findIndex((e) => e === optionValue);
        newValue.splice(index, 1);
      }
      else {
        newValue.push(optionValue);
      }

      this.$emit('update:modelValue', newValue);

      this.scrollActiveItemIntoView(optionIndex);
    },

    isActive(option) {
      return this.modelValue.includes(this.getOptionValue(option));
    },

    isLocked(option) {
      return option.isAllAccess && this.isAllAccess === false;
    },

    scrollActiveItemIntoView(index) {
      if (this.shouldScrollActiveItemIntoView) {
        if (typeof index === 'number') {
          const options = { behavior: 'smooth', block: 'end', inline: 'center' };
          scrollActiveItemIntoView(this, 'listItems', index, options);
        }
        else {
          const firstActiveOption = this.options.findIndex((option) => this.isActive(option));
          scrollActiveItemIntoView(this, 'listItems', firstActiveOption);
        }
      }
    },
  },
};
</script>

<style module>
/**
 * Hide scrollbar yet allow content to still be scrolled!
 * SEE: https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll#answer-49278385
 * TODO: Consider moving these styles to a common location. They have been copied from
 * <SnowLocationNavigation>.
 */
.list {
  scrollbar-width: none; /* Firefox */
}

.list::-webkit-scrollbar { /* WebKit */
  height: 0;
  width: 0;
}
</style>
